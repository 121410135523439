<template>
<div>
<v-expand-transition>
  <v-card-text v-if="expandedTab" class="pb-0">
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" md="8">
          <v-row >
            <v-col cols="12" class="filters-container d-flex justify-space-between" :class="{ 'flex-wrap': $vuetify.breakpoint.mobile }">
              <v-col cols="6">
                <v-row>
                  <v-col cols="6">
                    <b>
                      <label for="name">{{ $t('filters.loadsDialog.name') }}</label>
                    </b>
                    <v-text-field
                      v-model="filters.name"
                      id="name"
                      outlined
                      required
                      validate-on-blur
                      dense
                      single-line
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col cols="6">
                    <label for="vehicleType">{{ $t('filters.bodyType') }}</label>
                    <v-select
                      v-model="filters.type.value"
                      id="vehicleType"
                      :items="vehicleTypes"
                      item-text="translatedValues[0].name"
                      item-value="id"
                      outlined
                      required
                      validate-on-blur
                      dense
                      single-line
                      hide-details
                      multiple>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" class="pt-0">
                    <label for="maxLoad">{{ $t('filters.templates') }}</label>
                    <v-select
                      v-model="selectedTemplate"
                      id="templates"
                      :items="templates"
                      item-text="name"
                      item-value="id"
                      outlined
                      required
                      validate-on-blur
                      dense
                      single-line
                      hide-details
                      @focus="fetchTemplate"
                      @change="templateChange">
                    </v-select>
                  </v-col>

                  <v-col cols="6" class="pt-0">
                    <label for="vehicleSizeType">{{ $t('filters.sizeType') }}</label>
                    <v-select
                      v-model="filters.size.value"
                      id="vehicleSizeType"
                      :items="vehicleSizeTypes"
                      item-text="description"
                      item-value="id"
                      outlined
                      required
                      validate-on-blur
                      dense
                      single-line
                      hide-details
                      multiple>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" class="pt-4 filters-container d-flex justify-space-between" :class="{ 'flex-wrap': $vuetify.breakpoint.mobile }" >
                    <v-btn color="primary" outlined block @click="displayMoreFiltersDialog = !displayMoreFiltersDialog" style="margin:0px; padding:0px;">
                      {{ $t('filters.more') }}
                    </v-btn>
                  </v-col>

                  <template v-if="freightMode">

                    <v-col cols="3" class="pt-0" xs="6" sm="6" md="3">
                      <label for="minLoad">{{ $t('filters.weightFrom') }}</label>
                      <v-text-field
                        v-model.number="filters.minFreightWeight.value"
                        id="minLoad"
                        type="number"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        hide-details
                        :rules="[validations.onlyNaturalNumbers, validations.load]"
                      />

                    </v-col>
                      <v-col cols="3" class="pt-0" xs="6" sm="6" md="3">
                      <label for="maxLoad">{{ $t('filters.weightTo') }}</label>
                        <v-text-field
                          v-model.number="filters.maxFreightWeight.value"
                          id="maxLoad"
                          type="number"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          hide-details
                          :rules="[validations.onlyNaturalNumbers, validations.load]"
                        />
                    </v-col>

                  </template>
                  <template v-else>

                    <v-col cols="3" class="pt-0">
                      <label for="minLoad">{{ $t('filters.weightVehicleFrom') }}</label>
                        <v-text-field
                          v-model.number="filters.minLoad.value"
                          id="minLoad"
                          type="number"
                          outlined
                          required
                          validate-on-blur
                          dense
                          single-line
                          hide-details
                          :rules="[validations.onlyNaturalNumbers, validations.load]"
                        />
                    </v-col>

                    <v-col cols="3" class="pt-0">
                      <label for="maxLoad">{{ $t('filters.weightVehicleTo') }}</label>
                      <v-text-field
                        v-model.number="filters.maxLoad.value"
                        id="maxLoad"
                        type="number"
                        outlined
                        required
                        validate-on-blur
                        dense
                        single-line
                        hide-details
                        :rules="[validations.onlyNaturalNumbers, validations.load]"
                      />
                    </v-col>

                  </template>
                </v-row>

                <v-row>
                  <v-col cols="6" class="filters-container d-flex justify-space-between" :class="{ 'flex-wrap': $vuetify.breakpoint.mobile }">
                    <v-btn color="primary" depressed block @click="addFilter">
                      {{ $t('filters.setFilter') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="6" class="pt-4" v-if="freightMode">
                    <v-checkbox v-model="filters.cabotage.value" class="pa-0 ma-0" hide-details>
                      <template #label>
                        <span>{{ $t('filters.loadsDialog.cabotage') }}</span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    {{$t('filters.filterByCompany')}}
                    <AutoComplete
                      v-model="filterCompanies"
                      :items="companies"
                      :label="$t('companies.groups.addCompanies')"
                      item-text="name"
                      item-value="id">
                    </AutoComplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                  {{$t('filters.filterByCompanyGroup')}}
                  <AutoComplete
                    v-model="filterGroups"
                    :items="companiesGroups"
                    :label="$t('companies.groups.addCompanies')"
                    item-text="name"
                    item-value="id">
                  </AutoComplete>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row>
                  <v-col cols="12" md="6">
                    <DatePicker v-model="filters.minStart.value" :label="$t('filters.loadDateFrom')" :rules="[validations.start]" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <DatePicker v-model="filters.maxStart.value" :label="$t('filters.loadDateTo')" :min="filters.minStart.value" :rules="[validations.start]" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0">
                    <label for="load">{{ $t('filters.load') }}</label>
                    <LocationInput
                      @removeItem="locationRemoveLoadInputHandler"
                      v-for="(location, index) in filters.locations.loadingPlaces"
                      :key="`load-${index}`"
                      v-model="location.value"
                      clearable
                    />

                    <v-btn v-if="!disabledAddLoadingPlaces" color="primary" class="mt-3" outlined :disabled="disabledAddLoadingPlaces" @click="addLoadLocationClick">
                      {{ $t('filters.add') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">

                <v-row >
                  <v-col cols="12" md="6">
                    <DatePicker v-model="filters.minEnd.value" :label="$t('filters.unloadDateFrom')" :rules="[validations.end]" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <DatePicker v-model="filters.maxEnd.value" :label="$t('filters.unloadDateTo')" :min="filters.minEnd.value" :rules="[validations.end]" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0">
                    <label for="unload">{{ $t('filters.unload') }}</label>
                    <LocationInput
                      @removeItem="locationRemoveUnloadInputHandler"
                      v-for="(location, index) in filters.locations.unloadingPlaces"
                      :key="`unload-${index}`"
                      v-model="location.value"
                      clearable
                    />
                    <v-btn v-if="!disabledAddUnloadingPlaces" color="primary" class="mt-3" outlined :disabled="disabledAddUnloadingPlaces" @click="addUnloadLocationClick">
                      {{ $t('filters.add') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <MoreFiltersDialog
      v-if="displayMoreFiltersDialog"
      v-model="displayMoreFiltersDialog"
      :data="filters"
      @change-filters="changeFilters"
    />
  </v-card-text>
</v-expand-transition>
</div>
</template>

<script>
import Service from '@/services'
import {
  BLOCKEDCOOPERATION,
  LOCATIONCROSS,
  LOCATIONSTRAIGHT,
  MAX,
  MIN,
  MULTI,
  MYCOMPANY,
  ONLYMINE,
  SEARCH,
  CABOTAGE,
  DEALBYUSER,
  GROUP
} from '@/types/condition-types'
import { DATETIME, DECIMAL, INT } from '@/types/filter-value-types'
import { catchMarketError, convertDataToFilters, convertFilters, createNewPlace } from '@/utils/market'
import { mapGetters } from 'vuex'
import LocationInput from '@/components/forms/LocationInput'
import AutoComplete from '@/components/forms/AutoComplete'
import DatePicker from '@/components/forms/DatePicker'
import moment from 'moment'

export default {
  name: 'Filters',
  components: {
    MoreFiltersDialog: () => import('@/views/Market/dialogs/MoreFiltersDialog'),
    LocationInput,
    AutoComplete,
    DatePicker
  },
  data () {
    return {
      companies: [],
      companiesGroups: [],
      valid: true,
      displayMoreFiltersDialog: false,
      vehicleTypes: [],
      templates: [],
      selectedTemplate: null,
      validations: {
        onlyNaturalNumbers: (v) => v ? v > 0 : true,
        load: () => (this.filters.minLoad.value && this.filters.maxLoad.value) ? this.filters.minLoad.value <= this.filters.maxLoad.value : true,
        start: () => (this.filters.minStart.value && this.filters.minStart.value.length > 0 && this.filters.maxStart.value && this.filters.maxStart.value.length > 0) ? moment(this.filters.minStart.value).unix() > moment(this.filters.maxStart.value).unix() ? this.$t('validation.dateRange') : true : true,
        end: () => (this.filters.minEnd.value && this.filters.minEnd.value.length > 0 && this.filters.maxEnd.value && this.filters.maxEnd.value.length > 0) ? moment(this.filters.minEnd.value).unix() > moment(this.filters.maxEnd.value).unix() ? this.$t('validation.dateRange') : true : true

      },
      filters: {
        name: '',
        onlyMine: {
          type: ONLYMINE,
          value: false
        },
        dealByUser: {
          type: DEALBYUSER,
          userId: null
        },
        group: {
          type: GROUP,
          value: null
        },
        myCompany: {
          type: MYCOMPANY,
          value: false
        },
        minHeight: {
          type: MIN,
          name: 'Height',
          valueType: DECIMAL,
          value: null
        },
        minLoad: {
          type: MIN,
          name: 'Load',
          valueType: DECIMAL,
          value: null
        },
        maxLoad: {
          type: MAX,
          name: 'Load',
          valueType: DECIMAL,
          value: null
        },
        minFreightWeight: {
          type: MIN,
          name: 'FreightWeight',
          valueType: DECIMAL,
          value: null
        },
        maxFreightWeight: {
          type: MAX,
          name: 'FreightWeight',
          valueType: DECIMAL,
          value: null
        },
        minStart: {
          type: MIN,
          name: 'Start',
          valueType: DATETIME,
          value: null
        },
        maxStart: {
          type: MAX,
          name: 'Start',
          valueType: DATETIME,
          value: null
        },
        minEnd: {
          type: MIN,
          name: 'End',
          valueType: DATETIME,
          value: null
        },
        maxEnd: {
          type: MAX,
          name: 'End',
          valueType: DATETIME,
          value: null
        },
        minLoadMeters: {
          type: MIN,
          name: 'LoadMeters',
          valueType: DECIMAL,
          value: null
        },
        maxLoadMeters: {
          type: MAX,
          name: 'LoadMeters',
          valueType: DECIMAL,
          value: null
        },
        minWidth: {
          type: MIN,
          name: 'Width',
          valueType: DECIMAL,
          value: null
        },
        minVolume: {
          type: MIN,
          name: 'Volume',
          valueType: DECIMAL,
          value: null
        },
        size: {
          type: MULTI,
          name: 'Size',
          value: null
        },
        loadForms: {
          type: MULTI,
          name: 'LoadForms',
          value: null
        },
        options: {
          type: MULTI,
          name: 'Options',
          value: null
        },
        type: {
          type: MULTI,
          name: 'Type',
          value: null
        },
        search: {
          type: SEARCH,
          value: null
        },
        locations: {
          type: LOCATIONCROSS,
          loadingPlaces: [
            { value: createNewPlace() }
          ],
          unloadingPlaces: [
            { value: createNewPlace() }
          ]
        },
        minPrice: {
          type: MIN,
          name: 'Price',
          valueType: DECIMAL,
          value: null
        },
        maxPrice: {
          type: MAX,
          name: 'Price',
          valueType: DECIMAL,
          value: null
        },
        currency: {
          type: MULTI,
          name: 'Currency',
          value: null
        },
        vehicleFreightTypes: {
          type: MULTI,
          name: 'VehicleFreightTypes',
          value: null
        },
        companyPayerStatus: {
          type: MULTI,
          name: 'CompanyPayerStatus',
          value: null
        },
        companyRating: {
          type: MIN,
          name: 'CompanyRating',
          valueType: DECIMAL,
          value: null
        },
        freightType: {
          type: MULTI,
          name: 'FreightType',
          value: null
        },
        daysForPayment: {
          type: MIN,
          name: 'DaysForPayment',
          valueType: INT,
          value: null
        },
        blockedCooperation: {
          type: BLOCKEDCOOPERATION,
          value: false
        },
        cabotage: {
          type: CABOTAGE,
          value: false
        },
        companyId: {
          type: MULTI,
          name: 'CompanyId',
          value: null
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currentTab: 'market/currentTab',
      expandedTab: 'market/expandedTab'
    }),
    vehicleSizeTypes () {
      return this.$store.getters.enums.vehicleSizeTypes
    },
    isLocationCross () {
      return this.filters.locations.type === LOCATIONCROSS
    },
    disabledLocationCrossButton () {
      const amountLoadingPlaces = this.filters.locations.loadingPlaces.length
      const amountUnloadingPlaces = this.filters.locations.unloadingPlaces.length
      const emptyLoadingPlaces = this.filters.locations.loadingPlaces.find(l => !l.value.country)
      const emptyUnloadingPlaces = this.filters.locations.unloadingPlaces.find(l => !l.value.country)
      return (amountLoadingPlaces !== amountUnloadingPlaces) || (amountUnloadingPlaces < 2 || amountUnloadingPlaces < 2) || !!(emptyLoadingPlaces || emptyUnloadingPlaces)
    },
    disabledAddLoadingPlaces () {
      return this.filters.locations.loadingPlaces.length > 4
    },
    disabledAddUnloadingPlaces () {
      return this.filters.locations.unloadingPlaces.length > 4
    },
    freightMode () {
      return this.$route.name === 'Loads' || this.$route.name === 'Freights'
    },
    filterCompanies: {
      get () {
        return this.filters.group.value?.companiesIds
      },
      set (val) {
        if (this.filters.group.value != null) {
          this.filters.group.value.companiesIds = val
        } else {
          this.filters.group.value = {
            companiesIds: val,
            groupCompanies: []
          }
        }

        if (val.length === 0) {
          if (this.filters.group.value.groupCompanies == null || this.filters.group.value.groupCompanies.length === 0) {
            this.filters.group.value = null
          }
        }
      }
    },
    filterGroups: {
      get () {
        const groups = this.companiesGroups

        return this.filters.group.value?.groupCompanies?.map(
          function (x) {
            return {
              id: x.id,
              name: groups.find(y => y.id === x.id).name
            }
          }
        )
      },
      set (val) {
        const result = []

        for (const x of val) {
          const group = this.companiesGroups.find(y => y.id === x)

          if (!result.some(y => y.id === group.id)) {
            result.push({ id: group.id })
          }
        }

        if (this.filters.group.value != null) {
          this.filters.group.value.groupCompanies = result
        } else {
          this.filters.group.value = {
            companiesIds: [],
            groupCompanies: result
          }
        }

        if (val.length === 0) {
          if (this.filters.group.value.companiesIds == null || this.filters.group.value.companiesIds.length === 0) {
            this.filters.group.value = null
          }
        }
      }
    }
  },
  watch: {
    currentTab (newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.filters = convertDataToFilters(this.currentTab)
      }
      this.selectedTemplate = null

      if (this.currentTab.name) {
        this.filters.name = this.currentTab.name
      }
    },
    expandedTab () {
      if (!this.filters.name && this.currentTab.name) {
        this.filters.name = this.currentTab.name
      }
    }
  },
  async created () {
    await this.fetchData()
    this.filters = convertDataToFilters(this.currentTab)
  },
  methods: {
    async fetchData () {
      try {
        const { data: vehicleTypes } = await Service.get('Dictionary/GetVehicleTypes')
        this.vehicleTypes = vehicleTypes

        this.companies = (await Service.get('Company/GetCompanyNames')).data

        const companiesGroupResponse = await Service.get('CompanyGroup/GetAll?page=1&pageSize=1000')
        this.companiesGroups = companiesGroupResponse.data.results
        await this.fetchTemplate()
      } catch (e) {
      }
    },
    async fetchTemplate () {
      try {
        const { data: templates } = await Service.get('Filter/GetListOfTemplatesForTab', { type: this.$store.getters['market/orderType'] })
        this.templates = templates
      } catch (e) {
      }
    },
    async addFilter () {
      if (!this.$refs.form.validate()) {
        return
      }
      const conditions = {
        ...this.filters,
        locations: {
          ...this.filters.locations,
          loadingPlaces: this.filters.locations.loadingPlaces.map(i => ({ ...i.value })),
          unloadingPlaces: this.filters.locations.unloadingPlaces.map(i => ({ ...i.value }))
        }
      }
      const filters = convertFilters(this.$store.getters['market/orderType'], conditions)
      await this.$store.dispatch('market/submitFilter', { model: { ...filters } })
      this.$store.commit('market/SET_EXPANDED_TAB', false)
    },
    addLoadLocationClick () {
      this.filters.locations.loadingPlaces.push({ value: createNewPlace() })
      this.filters.locations.type = LOCATIONCROSS
    },
    addUnloadLocationClick () {
      this.filters.locations.unloadingPlaces.push({ value: createNewPlace() })
      this.filters.locations.type = LOCATIONCROSS
    },
    async changeFilters (filters) {
      this.filters = filters
      await this.addFilter()
    },
    async templateChange () {
      try {
        const { data: template } = await Service.get('Filter/GetFilterTemplate', { filterId: this.selectedTemplate })
        this.filters = convertDataToFilters(template)
      } catch (e) {
        await catchMarketError(e)
      }
    },
    changeLocationsType () {
      if (this.filters.locations.type === LOCATIONSTRAIGHT) {
        this.filters.locations.type = LOCATIONCROSS
      } else {
        this.filters.locations.type = LOCATIONSTRAIGHT
      }
    },
    locationRemoveLoadInputHandler (value) {
      this.locationRemoveInputHandler(0, value)
    },
    locationRemoveUnloadInputHandler (value) {
      this.locationRemoveInputHandler(1, value)
    },
    locationRemoveInputHandler (type, value) {
      if (value.country == null) {
        let indexToRemove = -1
        const collection = type === 0
          ? this.filters.locations.loadingPlaces
          : this.filters.locations.unloadingPlaces

        collection
          .forEach(function (x, index) {
            if (indexToRemove !== -1) { return }

            indexToRemove = (collection.length === 2 || index > 0) && x.value.country == null
              ? index
              : -1
          })

        if (indexToRemove >= 0) {
          collection.splice(indexToRemove, 1)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
